import PropTypes from "prop-types"
import React from "react"
import Link from 'gatsby-link';
import { Container, Navbar, Nav, Image } from "react-bootstrap"

const Header = ({ siteTitle, blogPath, className }) => (
  <header className={`site-header bg-dark ${className}`}>
    <Container>
      <Navbar bg="dark" variant="dark" expand="lg">        
        <Link to="/" className="nav-brand">
          <Image src="/logo-white.svg" className="site-logo" alt={siteTitle}  />        
        </Link>        
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">              
            <Link className="nav-link" to={blogPath}>Blog</Link>
            <Link className="nav-link" to="/resume">Resume</Link>                   
            <Link className="nav-link" to="/about">About</Link>                        
            <Link className="nav-link" to="/contact">Contact</Link>             
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container> 
  </header> 
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  blogPath: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  blogPath: "/blog"
}

export default Header;