
import React from "react";
import cx from 'classnames';

import { Container } from "react-bootstrap";

class ContentContainer extends React.Component {

  constructor(props) {
    super(props); 
    const { px, pxlg, pxsm, text, textClassName } = this.props 
    this.classes = cx(    
      `px-md-${px || '8'}`,
      `px-lg-${pxlg || px || '8'}`,
      `px-sm-${pxsm || '2'}`,     
      this.props.className
    )
    this.mclasses = cx (
      `text-${text || 'muted content'}`,
      textClassName
    )
  }

  renderHeading() {
    if(this.props.heading) {
      return(
        <h1 className="pb-2 mt-4 mb-0 display-5 font-weight-bold">{this.props.heading}</h1>
       );
    }     
  }

  renderSubtitle() {
    if(this.props.heading) {
      return(
        <h2 className="pb-2 mt-0 mb-0 display-6 font-weight-bold">{this.props.subtitle}</h2>
       );
    } 
  }

  renderPostDate() {
    if(this.props.date) {
      return (
        <p className="text-muted mt-0 pt-0">{this.props.date}</p>
      );
    }
  }

  renderFeaturedImage() {
    if(this.props.featured_image) {
      return(
        <div>
          <img src={this.props.featured_image} alt="" width="100%" />
        </div>
      );
    }
  }

  renderHeadline() {  
    if(this.props.headline) {
      return(
        <p className="lead text-strong mb-4">{this.props.headline}</p>
      );
    }    
  }

  render() {
    return(
      <Container>
        <div className={this.classes}>
          { this.renderHeading() }
          { this.renderSubtitle() }
          { this.renderPostDate() }
          { this.renderHeadline() }
          { this.renderFeaturedImage() }
          <section id="main-content" className="mt-4">            
            {this.props.children}
          </section> 
        </div>
      </Container>
    );
  }
}

export default ContentContainer;