/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title,
          blogPath
        }
      }
    }
  `)

  const { title, blogPath } = data.site.siteMetadata;

  return (
    <>
      <Helmet>
        <link href="/loading1.gif" as="image" />
      </Helmet>
      <Header siteTitle={title} blogPath={blogPath} className="pb-1" />
      <main>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

